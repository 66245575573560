h1{
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

input{
    appearance: none;
    outline: none;
    border: none;
    background: #ffff;
    width: 100%;
    max-width: 30rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
}
button,a{
    appearance: none;
    outline: none;
    border: none;
    background: #1b2034;

    color:rgb(106, 237, 106);
    font-size: 1.5rem;
    line-height:1;
    text-decoration: none;
}

button{
    cursor:pointer;
}

img{
    display: block;
    width: 100%;
    max-width: 480px;
    margin:2rem auto;
}
